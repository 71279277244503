import React, { useState } from 'react'
import tw from 'twin.macro'
import axios from 'axios'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

import SEO from 'components/seo'
import Layout from 'components/layouts/layout'
import ContainedButton from 'components/buttons/contained-button'
import Input from 'components/input'
import Textarea from 'components/textarea'
import CountryDropdown from 'components/dropdown/country-dropdown'

export default ({ location }) => {
  const [sendingMessage, setSendingMessage] = useState(false)

  const handleSubmitForm = async event => {
    event.preventDefault()
    event.persist()

    setSendingMessage(true)

    try {
      const form = {
        name: event.target.name.value,
        email: event.target.email.value,
        phone: event.target.phone.value,
        country: event.target.country.value,
        message: event.target.message.value,
      }

      const url =
        process.env.FUNCTIONS_ENDPOINT + process.env.FUNCTIONS_CONTACT_EMAIL

      const { success } = await axios
        .post(url, { form })
        .then(res => res.data)
        .catch(err => {
          if (err.response) return err.response.data
          else throw Error('An error occured')
        })

      if (success) {
        event.target.reset()
        window.alert('Your message has been sent')
      } else {
        window.alert(
          'There is an error! Your message was not sent. Please try again later.'
        )
      }
    } catch (error) {
      window.alert('There is an error!')
    }

    setSendingMessage(false)
  }

  return (
    <Layout title="Contact">
      <SEO title="Contact" path={location.pathname} />

      <form
        className="mx-4 my-8 md:my-12 md:flex md:flex-row"
        onSubmit={handleSubmitForm}
      >
        <div className="md:w-2/5 md:mr-10">
          <Input
            name="name"
            label="Name"
            type="text"
            placeholder={'Your full name'}
            required
          />
          <Input
            name="phone"
            label="Phone Number"
            type="tel"
            placeholder={'Your phone number'}
            required
          />
          <Input
            name="email"
            label="Email"
            type="email"
            placeholder={'Your email'}
            required
          />
          <CountryDropdown name="country" label="Country" required />
        </div>

        <div className="md:flex-1">
          <Textarea
            name="message"
            label="Message"
            type="text"
            placeholder={'Your message'}
            rows={16}
            required
          />
          <div className="my-8 flex justify-end">
            <ContainedButton styles={[tw`w-32`]} disabled={sendingMessage}>
              {sendingMessage ? (
                <AiOutlineLoading3Quarters className="h-6 mx-auto animate-spin" />
              ) : (
                'Submit'
              )}
            </ContainedButton>
          </div>
        </div>
      </form>

      <div className="mx-4 border-b-2 border-gray-400"></div>
    </Layout>
  )
}
